export const useLog = (event: object) => {
  const f = useLocalStorage("f", Math.random().toString(36).substring(2, 12));
  const s = useSessionStorage("s", Math.random().toString(36).substring(2, 12));

  return $fetch("https://log.teslemetry.com/services/collector/event", {
    method: "POST",
    headers: {
      Authorization: "Splunk 2e42a5f2-cb2e-433d-bd5e-322108c49b43",
    },
    body: JSON.stringify({
      time: Date.now() / 1000,
      host: window.origin,
      event: { ...event, f: f.value, s: s.value },
    }),
  }).catch((e) => console.warn(e));
};
