import payload_plugin_oNepwii053 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.2_firebase-admin@12.4.0_firebase@10.13.0_vuefire@3.2.0/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_MqpWyKWj9o from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.0_eslint@9.9.1_typescript@5.5.4_vite@5.4.2_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_J6ryOOZCC8 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.0_eslint@9.9.1_typescript@5.5.4_vite@5.4.2_vue-tsc@2.0.29/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_mSl6P98LOw from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.0_eslint@9.9.1_typescript@5.5.4_vite@5.4.2_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_0Ugwy0zWcm from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@2.2.15_vite@5.4.2_vue@3.4.38/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_r2G4fYxSNO from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.0_eslint@9.9.1_typescript@5.5.4_vite@5.4.2_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_8VPmDUvWCS from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.0_eslint@9.9.1_typescript@5.5.4_vite@5.4.2_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_395LBmUD7c from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.0_eslint@9.9.1_typescript@5.5.4_vite@5.4.2_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Hxv6JFSXvr from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.0_eslint@9.9.1_typescript@5.5.4_vite@5.4.2_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _64nuxt_scripts_D34jXE4xNh from "/opt/buildhome/repo/.nuxt/modules/@nuxt-scripts.mjs";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_syyI5TEE6W from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.0_eslint@9.9.1_typescript@5.5.4_vite@5.4.2_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_ivXfN8hSzz from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.2_firebase-admin@12.4.0_firebase@10.13.0_vuefire@3.2.0/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_auth_plugin_client_cCwkwh1j5u from "/opt/buildhome/repo/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "/opt/buildhome/repo/.nuxt/vuefire-plugin.mjs";
import titles_a7r2QUEovf from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.19_h3@1.12.0_vite@5.4.2_vue@3.4.38/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_ygiZJF0A7x from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.0_vite@5.4.2_vue@3.4.38/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Fqts8wwpMP from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.0_vite@5.4.2_vue@3.4.38/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import slideovers_xtggdf3vR7 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.4_vite@5.4.2_vue@3.4.38/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_FkewJpkab1 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.4_vite@5.4.2_vue@3.4.38/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_X7Klo2KcSH from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.4_vite@5.4.2_vue@3.4.38/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_y8wE6HgTD4 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.4.4/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_nufhKCl8VF from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.4.10_vite@5.4.2_vue@3.4.38/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_EbNpUB3Vua from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.1_vite@5.4.2_vue@3.4.38/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_Zhhk2Q6W0J from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.1_vite@5.4.2_vue@3.4.38/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_z2EfDZnJHU from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.1_vite@5.4.2_vue@3.4.38/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import paddleJS_client_6OSkSWlVfm from "/opt/buildhome/repo/plugins/paddleJS.client.ts";
import sentry_client_shVUlIjFLk from "/opt/buildhome/repo/plugins/sentry.client.ts";
import defaults_SAXEkVsWF3 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.19_h3@1.12.0_vite@5.4.2_vue@3.4.38/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  payload_plugin_oNepwii053,
  revive_payload_client_MqpWyKWj9o,
  unhead_J6ryOOZCC8,
  router_mSl6P98LOw,
  _0_siteConfig_0Ugwy0zWcm,
  payload_client_r2G4fYxSNO,
  navigation_repaint_client_8VPmDUvWCS,
  check_outdated_build_client_395LBmUD7c,
  chunk_reload_client_Hxv6JFSXvr,
  _64nuxt_scripts_D34jXE4xNh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_syyI5TEE6W,
  plugin_client_ivXfN8hSzz,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  vuefire_plugin_8cq4FnzXKb,
  titles_a7r2QUEovf,
  siteConfig_ygiZJF0A7x,
  inferSeoMetaPlugin_Fqts8wwpMP,
  slideovers_xtggdf3vR7,
  modals_FkewJpkab1,
  colors_X7Klo2KcSH,
  plugin_client_y8wE6HgTD4,
  plugin_nufhKCl8VF,
  scrollbars_client_EbNpUB3Vua,
  presets_Zhhk2Q6W0J,
  variables_z2EfDZnJHU,
  paddleJS_client_6OSkSWlVfm,
  sentry_client_shVUlIjFLk,
  defaults_SAXEkVsWF3
]