import validate from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.0_eslint@9.9.1_typescript@5.5.4_vite@5.4.2_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/validate.js";
import log_45global from "/opt/buildhome/repo/middleware/log.global.ts";
import manifest_45route_45rule from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.0_eslint@9.9.1_typescript@5.5.4_vite@5.4.2_vue-tsc@2.0.29/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  log_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/opt/buildhome/repo/middleware/auth.ts"),
  ptxn: () => import("/opt/buildhome/repo/middleware/ptxn.ts")
}