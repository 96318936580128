import { default as _91_46_46_46slug_93ZvihMONwlrMeta } from "/opt/buildhome/repo/pages/api/1/[...slug].vue?macro=true";
import { default as checkoutfrrPZSvI7jMeta } from "/opt/buildhome/repo/pages/checkout.vue?macro=true";
import { default as _91vin_93WUxCsgWOHhMeta } from "/opt/buildhome/repo/pages/console/[vin].vue?macro=true";
import { default as indexOIuozk3f04Meta } from "/opt/buildhome/repo/pages/console/index.vue?macro=true";
import { default as deleteImZtClZEzFMeta } from "/opt/buildhome/repo/pages/delete.vue?macro=true";
import { default as _91_46_46_46slug_93lnRvSSjMOFMeta } from "/opt/buildhome/repo/pages/docs/[...slug].vue?macro=true";
import { default as docs84AgECXoU4Meta } from "/opt/buildhome/repo/pages/docs.vue?macro=true";
import { default as error5bJL3YgCAEMeta } from "/opt/buildhome/repo/pages/error.vue?macro=true";
import { default as homeassistantbmuMFPaeCkMeta } from "/opt/buildhome/repo/pages/feature/homeassistant.vue?macro=true";
import { default as webhooksKP3AINXlVgMeta } from "/opt/buildhome/repo/pages/feature/webhooks.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as migrateLEUP44Bwu4Meta } from "/opt/buildhome/repo/pages/migrate.vue?macro=true";
import { default as pricingQrzbg66BTdMeta } from "/opt/buildhome/repo/pages/pricing.vue?macro=true";
import { default as redirectx0QV1tiGZfMeta } from "/opt/buildhome/repo/pages/redirect.vue?macro=true";
import { default as statusbgyZkRr6pYMeta } from "/opt/buildhome/repo/pages/status.vue?macro=true";
export default [
  {
    name: "api-1-slug",
    path: "/api/1/:slug(.*)*",
    meta: _91_46_46_46slug_93ZvihMONwlrMeta || {},
    component: () => import("/opt/buildhome/repo/pages/api/1/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: checkoutfrrPZSvI7jMeta || {},
    component: () => import("/opt/buildhome/repo/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: "console-vin",
    path: "/console/:vin()",
    meta: _91vin_93WUxCsgWOHhMeta || {},
    component: () => import("/opt/buildhome/repo/pages/console/[vin].vue").then(m => m.default || m)
  },
  {
    name: "console",
    path: "/console",
    meta: indexOIuozk3f04Meta || {},
    component: () => import("/opt/buildhome/repo/pages/console/index.vue").then(m => m.default || m)
  },
  {
    name: "delete",
    path: "/delete",
    component: () => import("/opt/buildhome/repo/pages/delete.vue").then(m => m.default || m)
  },
  {
    name: "docs",
    path: "/docs",
    component: () => import("/opt/buildhome/repo/pages/docs.vue").then(m => m.default || m),
    children: [
  {
    name: "docs-slug",
    path: ":slug(.*)*",
    component: () => import("/opt/buildhome/repo/pages/docs/[...slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "error",
    path: "/error",
    component: () => import("/opt/buildhome/repo/pages/error.vue").then(m => m.default || m)
  },
  {
    name: "feature-homeassistant",
    path: "/feature/homeassistant",
    component: () => import("/opt/buildhome/repo/pages/feature/homeassistant.vue").then(m => m.default || m)
  },
  {
    name: "feature-webhooks",
    path: "/feature/webhooks",
    component: () => import("/opt/buildhome/repo/pages/feature/webhooks.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: login4dfr6kRqBoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "migrate",
    path: "/migrate",
    meta: migrateLEUP44Bwu4Meta || {},
    component: () => import("/opt/buildhome/repo/pages/migrate.vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    component: () => import("/opt/buildhome/repo/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "redirect",
    path: "/redirect",
    meta: redirectx0QV1tiGZfMeta || {},
    component: () => import("/opt/buildhome/repo/pages/redirect.vue").then(m => m.default || m)
  },
  {
    name: "status",
    path: "/status",
    component: () => import("/opt/buildhome/repo/pages/status.vue").then(m => m.default || m)
  }
]